const API_BASE = '/api' as const
const EMAIL_BASE = `${API_BASE}/email` as const
const ADMIN_BASE = `${API_BASE}/admin` as const
const AI_BASE = `${API_BASE}/ai` as const
const DEVELOPMENT_PLAN_BASE = `${API_BASE}/development-plans` as const
const USER_BASE = `${API_BASE}/user` as const
const PEERS_SELECTION_BASE = `${API_BASE}/peers-selection` as const
const FEEDBACK_MODERATION_BASE = `${ADMIN_BASE}/feedback` as const
const BO_CAPABILITIES = `${ADMIN_BASE}/capabilities` as const
const BO_META_THEMES = `${ADMIN_BASE}/meta-themes` as const
const BO_PILLARS = `${ADMIN_BASE}/pillars` as const
const BO_QUESTIONS = `${ADMIN_BASE}/questions` as const
const BO_ENGAGEMENT_DRIVERS = `${ADMIN_BASE}/engagement-drivers` as const
const BO_DRIVER_TYPES = `${ADMIN_BASE}/driver-types` as const
const SURVEY_MODERATION_BASE = `${ADMIN_BASE}/survey` as const
const SURVEY_REQUEST_BASE = `${ADMIN_BASE}/survey-request` as const
const SURVEY_RESPONSE_BASE = `${ADMIN_BASE}/survey-response` as const
const BATCH_BASE = `${ADMIN_BASE}/batch` as const
const ADMIN_USERS_BASE = `${ADMIN_BASE}/users` as const
const COMPANY_DIRECTORY_BASE = `${API_BASE}/company-directory` as const
const PEOPLE_DIRECTORY_BASE = `${API_BASE}/people-directory` as const
const OBJECTIVES_DIRECTORY_BASE = `${API_BASE}/objectives` as const
const INTERVIEWS_DIRECTORY_BASE = `${API_BASE}/interviews` as const
const RESPONDENT_BASE = `${API_BASE}/respondent` as const
const TEACHER_GRADE_SHEET_BASE = `${API_BASE}/grade-sheet` as const
const TRACK_SEEN_CONTENT_BASE = `${API_BASE}/track-seen-content` as const
const POST_BATCH_COMMENT_BASE = `${API_BASE}/post-batch-comment` as const
const PULSE_SHARING_BASE = `${API_BASE}/pulse-sharing` as const
const FEEDBACKS_BASE = `${API_BASE}/feedbacks` as const
const KUDOS_BASE = `${FEEDBACKS_BASE}/kudos` as const
const NOTIFICATIONS_BASE = `${API_BASE}/notifications` as const
const SURVEY_BASE = `${API_BASE}/survey` as const
const SELF_COMMENT_BASE = `${API_BASE}/self-comment` as const
const SURVEY_EDITOR_BASE = `${API_BASE}/survey-editor` as const
const PULSE_BASE = `${API_BASE}/pulse` as const
const BO_LEARNING_BASE = `${ADMIN_BASE}/learnings` as const
const LEARNING_BASE = `${API_BASE}/learnings` as const
const LEARNING_LIKE_BASE = `${LEARNING_BASE}/likes` as const
const ENGAGEMENT_SNAPSHOT_BASE = `${API_BASE}/engagement-snapshot` as const
const COMPANY_PROGRESS_BASE = `${API_BASE}/company-progress` as const
const CAPABILITIES_BASE = `${API_BASE}/capabilities` as const
const IA_READY_BASE = `${API_BASE}/ia-ready` as const
const SURVEY_MODULES_BASE = `${API_BASE}/survey-modules` as const
const AI_SURVEY_ANALYSIS_BASE = `${API_BASE}/ai-survey-analysis` as const
const ADMIN_TRANSLATION_BASE = `${ADMIN_BASE}/translation` as const
const PROFILE_FLASHERS_BASE = `${API_BASE}/profile-flashers` as const
const TRANSLATION_BASE = `${API_BASE}/translation` as const
const NUDGE_BASE = `${API_BASE}/nudge` as const
const ADMIN_NUDGE_BASE = `${ADMIN_BASE}/nudges` as const
const ADMIN_FEELINGS_SHARE_BASE = `${ADMIN_BASE}/feelings-share` as const
const ERICSSON_POC_BASE = `${API_BASE}/ericsson-poc` as const
const FORFAIT_JOUR_INTERVIEWS_BASE = `${API_BASE}/forfait-jour-interviews` as const
const LEARNING_PROGRAM_BASE = `${API_BASE}/learning-programs` as const
const ADMIN_LEARNING_PROGRAM_BASE = `${ADMIN_BASE}/learning-programs` as const

export const API = {
  admin: {
    ai: {
      callOpenAi: `${ADMIN_BASE}/ai/createOpenAiCall`,
      getOpenAiCall: `${ADMIN_BASE}/ai/getOpenAiCall`,
      getOpenAiCalls: `${ADMIN_BASE}/ai/getOpenAiCalls`,
      getBatchSummaryInputsByManager: `${ADMIN_BASE}/ai/getBatchSummaryInputsByManager`,
      getBatchSummaries: `${ADMIN_BASE}/ai/get-batch-summaries`,
      generateSummary: `${ADMIN_BASE}/ai/generateSummary`,
      reviewSummary: `${ADMIN_BASE}/ai/reviewSummary`,
      editSummary: `${ADMIN_BASE}/ai/edit-summary`,
      openaiLogs: `${ADMIN_BASE}/ai/openaiLogs`,
      openaiLog: `${ADMIN_BASE}/ai/openaiLog`,
      getProficiencyTables: `${ADMIN_BASE}/ai/getProficiencyTables`,
      getImage: `${ADMIN_BASE}/ai/getImage`,
      getImageProcessIds: `${ADMIN_BASE}/ai/getImageProcessIds`,
      setImage: `${ADMIN_BASE}/ai/setImage`,
    },
    aiGuided: {
      add: `${API_BASE}/aiGuided/add`,
      clone: `${API_BASE}/aiGuided/clone`,
      createShare: `${API_BASE}/aiGuided/createShare`,
      delete: `${API_BASE}/aiGuided/delete`,
      edit: `${API_BASE}/aiGuided/edit`,
      getNext: `${API_BASE}/aiGuided/getNext`,
      getShares: `${API_BASE}/aiGuided/getShares`,
      extractInformation: `${API_BASE}/aiGuided/extractInformation`,
    },
    batch: {
      get: `${BATCH_BASE}/get-batches`,
      getBatch: `${BATCH_BASE}/get-batch`,
      getAll: `${BATCH_BASE}/get-el-batches`,
      getIds: `${BATCH_BASE}/get-batch-ids`,
      getProgressionData: `${BATCH_BASE}/getProgressionData`,
      getEvolutionLinks: `${BATCH_BASE}/getEvolutionLinks`,
      addEvolutionLink: `${BATCH_BASE}/addEvolutionLink`,
      removeEvolutionLink: `${BATCH_BASE}/removeEvolutionLink`,
      close: `${BATCH_BASE}/close-batch`,
      closeElBatch: `${BATCH_BASE}/close-elbatch`,
      sendLastReminder: `${BATCH_BASE}/batch-last-reminder`,
      updateFilterLabels: `${BATCH_BASE}/set-batch-filter-labels`,
      updateElBatchFilterLabels: `${BATCH_BASE}/set-elbatch-filter-labels`,
      updateEvolutionMapping: `${BATCH_BASE}/edit-evolution-mapping`,
      updateElEvolutionMapping: `${BATCH_BASE}/updateElEvolutionMapping`,
      updateAccessGrants: `${BATCH_BASE}/updateAccessGrants`,
      updateAccessAggregatedEngagementBatchData: `${BATCH_BASE}/updateAccessAggregatedEngagementBatchData`,
      updateName: `${BATCH_BASE}/updateName`,
      updateFilters: `${BATCH_BASE}/updateFilters`,
      translateBatchOpenTexts: `${BATCH_BASE}/translateBatchOpenTexts`,
      updateTabAccess: `${BATCH_BASE}/updateTabAccess`,
      addTabAccess: `${BATCH_BASE}/addTabAccess`,
      getBatchTabAccessesByEmail: `${BATCH_BASE}/getBatchTabAccessesByEmail`,
      resetDemoData: `${BATCH_BASE}/resetDemoData`,
    },
    capabilities: {
      get: `${BO_CAPABILITIES}/get-capabilities`,
      getOne: `${BO_CAPABILITIES}/getOne`,
      create: `${BO_CAPABILITIES}/create-capability`,
      edit: `${BO_CAPABILITIES}/edit-capability`,
      editProficiencyLevels: `${BO_CAPABILITIES}/editProficiencyLevels`,
    },
    company: {
      getHierarchy: `${ADMIN_BASE}/company/getHierarchy`,
    },
    engagementDrivers: {
      get: `${BO_ENGAGEMENT_DRIVERS}/get-engagement-drivers`,
      create: `${BO_ENGAGEMENT_DRIVERS}/createEngagementDriver`,
      getOne: `${BO_ENGAGEMENT_DRIVERS}/get-engagement-driver`,
      edit: `${BO_ENGAGEMENT_DRIVERS}/edit-engagement-driver`,
    },
    driverTypes: {
      get: `${BO_DRIVER_TYPES}/get-driver-types`,
    },
    feedback: {
      comment: `${FEEDBACK_MODERATION_BASE}/comment`,
      decline: `${FEEDBACK_MODERATION_BASE}/decline`,
      edit: `${FEEDBACK_MODERATION_BASE}/edit`,
      validate: `${FEEDBACK_MODERATION_BASE}/validate`,
      get: `${FEEDBACK_MODERATION_BASE}/get`,
      getOther: `${FEEDBACK_MODERATION_BASE}/get-other`,
      editOther: `${FEEDBACK_MODERATION_BASE}/edit-other`,
      validateOther: `${FEEDBACK_MODERATION_BASE}/validate-other`,
      declineOther: `${FEEDBACK_MODERATION_BASE}/decline-other`,
      count: `${FEEDBACK_MODERATION_BASE}/count`,
    },
    learnings: {
      get: `${BO_LEARNING_BASE}/get-learnings`,
      create: `${BO_LEARNING_BASE}/create-learning`,
      edit: `${BO_LEARNING_BASE}/edit-learning`,
    },
    metaThemes: {
      get: `${BO_META_THEMES}/get-meta-themes`,
      create: `${BO_META_THEMES}/create-meta-theme`,
      edit: `${BO_META_THEMES}/edit-meta-theme`,
    },
    pillars: {
      get: `${BO_PILLARS}/get-pillars`,
    },
    questions: {
      get: `${BO_QUESTIONS}/get-questions`,
      getOne: `${BO_QUESTIONS}/get-one`,
      getCapabilityProficiencyLevelChoices: `${BO_QUESTIONS}/getCapabilityProficiencyLevelChoices`,
      edit: `${BO_QUESTIONS}/edit-question`,
      create: `${BO_QUESTIONS}/create-question`,
      search: `${BO_QUESTIONS}/search`,
      searchCapabilityQuestions: `${BO_QUESTIONS}/search-capability-questions`,
    },
    survey: {
      getFromNotion: `${SURVEY_MODERATION_BASE}/get-from-notion`,
      create: `${SURVEY_MODERATION_BASE}/create`,
      edit: `${SURVEY_MODERATION_BASE}/edit`,
      getAll: `${SURVEY_MODERATION_BASE}/get-all`,
      getOne: `${SURVEY_MODERATION_BASE}/get-one`,
      getSurveyDisplay: `${SURVEY_MODERATION_BASE}/getSurveyDisplay`,
      delete: `${SURVEY_MODERATION_BASE}/delete`,
      grantSurveyAccess: `${SURVEY_MODERATION_BASE}/grantSurveyAccess`,
      editSurveyAccess: `${SURVEY_MODERATION_BASE}/editSurveyAccess`,
      revokeSurveyAccess: `${SURVEY_MODERATION_BASE}/revokeSurveyAccess`,
    },
    surveyRequest: {
      createReviewRequest: `${SURVEY_REQUEST_BASE}/create-review-request`,
    },
    surveyResponse: {
      archive: `${SURVEY_RESPONSE_BASE}/archive`,
      getStatLines: `${SURVEY_RESPONSE_BASE}/get-stat-lines`,
      getUnfinisheds: `${SURVEY_RESPONSE_BASE}/getNotFullyProcessedSurveyResponses`,
      reprocessSurvey: `${SURVEY_RESPONSE_BASE}/reprocessSurveyResponse`,
    },
    syncNotion: `${ADMIN_BASE}/sync-notion-db/sync`,
    users: {
      emailNames: `${ADMIN_USERS_BASE}/get-emails-and-names`,
      emails: `${ADMIN_USERS_BASE}/get-emails`,
      getEntreprise: `${ADMIN_USERS_BASE}/get-entreprise`,
      getSchool: `${ADMIN_USERS_BASE}/get-school`,
      grantAccess: `${ADMIN_USERS_BASE}/grant-access`,
      editAccessibleProfiles: `${ADMIN_USERS_BASE}/editAccessibleProfiles`,
      removeAccessibleProfile: `${ADMIN_USERS_BASE}/removeAccessibleProfile`,
    },
    translation: {
      getTranslation: `${ADMIN_TRANSLATION_BASE}/get-translation`,
    },
    nudges: {
      getAllNudges: `${ADMIN_NUDGE_BASE}/getAllNudges`,
    },
    feelingsShare: {
      getFeelingsShare: `${ADMIN_FEELINGS_SHARE_BASE}/getFeelingsShare`,
      updateFeelingsShareStatus: `${ADMIN_FEELINGS_SHARE_BASE}/updateFeelingsShareStatus`,
    },
    learningProgram: {
      createLearningProgram: `${ADMIN_LEARNING_PROGRAM_BASE}/createLearningProgram`,
      updateLearningProgram: `${ADMIN_LEARNING_PROGRAM_BASE}/updateLearningProgram`,
      getLearningProgram: `${ADMIN_LEARNING_PROGRAM_BASE}/getLearningProgram`,
      getLearningProgramsList: `${ADMIN_LEARNING_PROGRAM_BASE}/getLearningProgramsList`,
      getLearningProgramAccesses: `${ADMIN_LEARNING_PROGRAM_BASE}/getLearningProgramAccesses`,
      giveUserAccessToLearningProgram: `${ADMIN_LEARNING_PROGRAM_BASE}/giveUserAccessToLearningProgram`,
      editUserAccessToLearningProgram: `${ADMIN_LEARNING_PROGRAM_BASE}/editUserAccessToLearningProgram`,
      removeUserAccessToLearningProgram: `${ADMIN_LEARNING_PROGRAM_BASE}/removeUserAccessToLearningProgram`,
      addModuleToLearningProgram: `${ADMIN_LEARNING_PROGRAM_BASE}/addModuleToLearningProgram`,
      launchPhase: `${ADMIN_LEARNING_PROGRAM_BASE}/launchPhase`,
      updateModuleInLearningProgram: `${ADMIN_LEARNING_PROGRAM_BASE}/updateModuleInLearningProgram`,
      deleteModule: `${ADMIN_LEARNING_PROGRAM_BASE}/deleteModule`,
      sendPhaseLaunchEmails: `${ADMIN_LEARNING_PROGRAM_BASE}/sendPhaseLaunchEmails`,
      publishPhaseResults: `${ADMIN_LEARNING_PROGRAM_BASE}/publishPhaseResults`,
      closePhase: `${ADMIN_LEARNING_PROGRAM_BASE}/closePhase`,
    },
    userinfos: {
      addUserInfos: `${ADMIN_BASE}/userinfos/addUserInfos`,
    },
  },
  ai: {
    get: `${AI_BASE}/get`,
    getTemp: `${AI_BASE}/get-temp`,
    openaiLog: `${AI_BASE}/openaiLog`,
  },
  aiChat: {
    getAiCompanionNext: `${API_BASE}/aiGuided/getAiCompanionNext`,
    getNext: `${API_BASE}/aiGuided/getNext`,
    extractInformation: `${API_BASE}/aiGuided/extractInformation`,
    launchAiFollowSingleAnalysis: `${API_BASE}/aiChat/launchAiFollowSingleAnalysis`,
    getAiFollowSingle: `${API_BASE}/aiChat/getAiFollowSingle`,
  },
  aiSurveyAnalysis: {
    computeFacetGlobalAssessment: `${AI_SURVEY_ANALYSIS_BASE}/computeFacetGlobalAssessment`,
    computeFacetProficiencyLevel: `${AI_SURVEY_ANALYSIS_BASE}/computeFacetProficiencyLevel`,
    computeProficiencyLevel: `${AI_SURVEY_ANALYSIS_BASE}/compute-proficiency-level`,
    getBySurveys: `${AI_SURVEY_ANALYSIS_BASE}/get-by-surveys`,
    getResults: `${AI_SURVEY_ANALYSIS_BASE}/getResults`,
    retryIfIncomplete: `${AI_SURVEY_ANALYSIS_BASE}/retryIfIncomplete`,
    relaunchCompute: `${AI_SURVEY_ANALYSIS_BASE}/relaunchCompute`,
    hasDoneOne: `${AI_SURVEY_ANALYSIS_BASE}/hasDoneOne`,
    getFacetedAiModuleData: `${AI_SURVEY_ANALYSIS_BASE}/getFacetedAiModuleData`,
    getCapaWithOTResults: `${AI_SURVEY_ANALYSIS_BASE}/getCapaWithOTResults`,
    saveConversationalShareEmail: `${AI_SURVEY_ANALYSIS_BASE}/saveConversationalShareEmail`,
  },
  batch: {
    admin: {
      archiveFeedback: `${API_BASE}/batch/admin/archiveFeedback`,
      editOpenTextAiSummary: `${API_BASE}/batch/admin/editOpenTextAiSummary`,
      regenerateOpenTextSummary: `${API_BASE}/batch/admin/regenerateOpenTextSummary`,
    },
    ai: {
      editCapabilityAiOpenTextSummary: `${API_BASE}/batch/ai/editCapabilityAiOpenTextSummary`,
      generateCapabilityAiOpenTextSummary: `${API_BASE}/batch/ai/generateCapabilityAiOpenTextSummary`,
      getCapabilityAiOpenTextSummaries: `${API_BASE}/batch/ai/getCapabilityAiOpenTextSummaries`,
      getOpenTextAiSummary: `${API_BASE}/batch/ai/getOpenTextAiSummary`,
      getOpenTextAiSummaries: `${API_BASE}/batch/ai/getOpenTextAiSummaries`,
      generateOpenTextSummary: `${API_BASE}/batch/ai/generateOpenTextSummary`,
      getGlobalAiSummary: `${API_BASE}/batch/ai/getGlobalAiSummary`,
    },
    getAccess: `${API_BASE}/batch/get-access`,
    getAreResultsAccessible: `${API_BASE}/batch/get-results-accessible`,
    getEngagementData: `${API_BASE}/batch/get-engagement-data`,
    getCompanyEngagementData: `${API_BASE}/batch/get-company-engagement-data`,
    getEngagementPrevious: `${API_BASE}/batch/get-engagement-previous-data`,
    getEngagementHierarchyEvolutionTables: `${API_BASE}/batch/getEngagementHierarchyEvolutionTables`,
    getSplittableEngagementPrevious: `${API_BASE}/batch/getSplittableEngagementPrevious`,
    getEngagementKeyStats: `${API_BASE}/batch/get-engagement-key-stats`,
    getEngagementCompletionRate: `${API_BASE}/batch/getEngagementCompletionRate`,
    getAnonymousEngagementCompletionRate: `${API_BASE}/batch/getAnonymousEngagementCompletionRate`,
    getCapabilityData: `${API_BASE}/batch/get-capability-data`,
    getCapabilityBatchSurveyCompletion: `${API_BASE}/batch/get-survey-completion`,
    getHierarchicalCapabilityKeyStats: `${API_BASE}/batch/getHierarchicalCapabilityKeyStats`,
    getFilters: `${API_BASE}/batch/get-filters`,
    getAnonymousFilters: `${API_BASE}/batch/getAnonymousFilters`,
    getAnonymousEngagementData: `${API_BASE}/batch/getAnonymousEngagementData`,
    getAnonymousEngagementKeyStats: `${API_BASE}/batch/getAnonymousEngagementKeyStats`,
    getAnonymousEngagementMeta: `${API_BASE}/batch/getAnonymousEngagementMeta`,
    getSplittableCapabilitiesData: `${API_BASE}/batch/getSplittableCapabilitiesData`,
    getSplittableEngagementData: `${API_BASE}/batch/getSplittableEngagementData`,
    getSplittableFilters: `${API_BASE}/batch/getSplittableFilters`,
    getSplittableEngagementMeta: `${API_BASE}/batch/getSplittableEngagementMeta`,
    getSplittableEngagementCompletionRate: `${API_BASE}/batch/getSplittableEngagementCompletionRate`,
    getSplittableEngagementKeyStats: `${API_BASE}/batch/getSplittableEngagementKeyStats`,
    getSplittableCapabilityKeyStats: `${API_BASE}/batch/getSplittableCapabilityKeyStats`,
    getEngagementEvolutionLinkTable: `${API_BASE}/batch/getEngagementEvolutionLinkTable`,
    getFilter: `${API_BASE}/batch/get-filter`,
    getCapabilityMeta: `${API_BASE}/batch/get-capability-meta`,
    getEngagementMeta: `${API_BASE}/batch/get-engagement-meta`,
    update: `${API_BASE}/batch/update`,
    translations: {
      getBatchTranslations: `${API_BASE}/batch/translations/getBatchTranslations`,
      getBatchTranslation: `${API_BASE}/batch/translations/getBatchTranslation`,
    },
    getBatchConditionalInfos: `${API_BASE}/batch/getBatchConditionalInfos`,
  },
  companyDirectory: {
    getCompanyDirectory: `${COMPANY_DIRECTORY_BASE}/get-company-directory`,
    getSearchResults: `${COMPANY_DIRECTORY_BASE}/get-search-results`,
  },
  developmentPlan: {
    getSelf: `${DEVELOPMENT_PLAN_BASE}/get-self`,
    getAbout: `${DEVELOPMENT_PLAN_BASE}/get-about`,
    create: `${DEVELOPMENT_PLAN_BASE}/create`,
    createMandatory: `${DEVELOPMENT_PLAN_BASE}/create-mandatory`,
    edit: `${DEVELOPMENT_PLAN_BASE}/edit`,
    delete: `${DEVELOPMENT_PLAN_BASE}/delete`,
    accept: `${DEVELOPMENT_PLAN_BASE}/accept`,
    setDone: `${DEVELOPMENT_PLAN_BASE}/set-done`,
    review: `${DEVELOPMENT_PLAN_BASE}/review`,
    reviewMany: `${DEVELOPMENT_PLAN_BASE}/review-many`,
    getTeam: `${DEVELOPMENT_PLAN_BASE}/get-team`,
    getOrg: `${DEVELOPMENT_PLAN_BASE}/get-org`,
    editManagerComment: `${DEVELOPMENT_PLAN_BASE}/edit-manager-comment`,
  },
  email: {
    afterSelfCompletion: `${EMAIL_BASE}/after-self-completion`,
    isInTeamMember: `${EMAIL_BASE}/is-in-team-member`,
    resendEmailVerification: `${EMAIL_BASE}/resendEmailVerification`,
    resendEmailFailed: `${EMAIL_BASE}/resendEmailFailed`,
    checkEmailVerified: `${EMAIL_BASE}/checkEmailVerified`,
  },
  feedbacks: {
    admin: { archive: `${FEEDBACKS_BASE}/admin/archive` },
    kudos: {
      create: `${KUDOS_BASE}/create`,
      getAbout: `${KUDOS_BASE}/get-about`,
      getFromEmail: `${KUDOS_BASE}/get-from-email`,
    },
  },
  interview: {
    getSelf: `${INTERVIEWS_DIRECTORY_BASE}/get-self`,
    getOne: `${INTERVIEWS_DIRECTORY_BASE}/get-one`,
    createFromComment: `${INTERVIEWS_DIRECTORY_BASE}/create-from-comment`,
    createFromManagerComment: `${INTERVIEWS_DIRECTORY_BASE}/create-from-manager-comment`,
    editEmployeeComment: `${INTERVIEWS_DIRECTORY_BASE}/edit-employee-comment`,
    editManagerComment: `${INTERVIEWS_DIRECTORY_BASE}/edit-manager-comment`,
    signatureManager: `${INTERVIEWS_DIRECTORY_BASE}/signature-manager`,
    signatureEmployee: `${INTERVIEWS_DIRECTORY_BASE}/signature-employee`,
    getFromEmail: `${INTERVIEWS_DIRECTORY_BASE}/get-interviews-from-email`,
  },
  learning: {
    get: `${LEARNING_BASE}/get`,
    getPulseLearnings: `${LEARNING_BASE}/get-pulse-learnings`,
    like: {
      upsert: `${LEARNING_LIKE_BASE}/upsert`,
      getByAuthor: `${LEARNING_LIKE_BASE}/get-by-author`,
      getOne: `${LEARNING_LIKE_BASE}/get-one`,
    },
  },
  logTests: {
    throwError: `${ADMIN_BASE}/log-test?type=throw`,
    consoleError: `${ADMIN_BASE}/log-test?type=console-error`,
    consoleWarn: `${ADMIN_BASE}/log-test?type=console-warn`,
  },
  notifications: {
    getNotifications: `${NOTIFICATIONS_BASE}/get-notifications`,
    setAsRead: `${NOTIFICATIONS_BASE}/set-as-read`,
  },
  objectives: {
    create: `${OBJECTIVES_DIRECTORY_BASE}/create`,
    delete: `${OBJECTIVES_DIRECTORY_BASE}/delete`,
    edit: `${OBJECTIVES_DIRECTORY_BASE}/edit`,
    editManagerComment: `${OBJECTIVES_DIRECTORY_BASE}/edit-manager-comment`,
    evaluate: `${OBJECTIVES_DIRECTORY_BASE}/evaluate`,
    getFromEmail: `${OBJECTIVES_DIRECTORY_BASE}/get-from-email`,
    getOrg: `${OBJECTIVES_DIRECTORY_BASE}/get-org`,
    getTeam: `${OBJECTIVES_DIRECTORY_BASE}/get-team`,
    getSelf: `${OBJECTIVES_DIRECTORY_BASE}/get-self`,
    reviewMany: `${OBJECTIVES_DIRECTORY_BASE}/review-many`,
    review: `${OBJECTIVES_DIRECTORY_BASE}/review`,
    addAttachment: `${OBJECTIVES_DIRECTORY_BASE}/addAttachment`,
    deleteAttachment: `${OBJECTIVES_DIRECTORY_BASE}/deleteAttachment`,
    storageUpload: `${OBJECTIVES_DIRECTORY_BASE}/storageUpload`,
    storageDownload: `${OBJECTIVES_DIRECTORY_BASE}/storageDownload`,
  },
  peersSelection: {
    saveAndCreateSurveys: `${PEERS_SELECTION_BASE}/save-and-create-surveys`,
    getPeerSelection: `${PEERS_SELECTION_BASE}/get-peer-selection`,
    getPeersDirectory: `${PEERS_SELECTION_BASE}/get-peers-directory`,
  },
  peopleDirectory: {
    getPeopleDirectory: `${PEOPLE_DIRECTORY_BASE}/get-directory`,
    create: `${PEOPLE_DIRECTORY_BASE}/create`,
    edit: `${PEOPLE_DIRECTORY_BASE}/edit`,
    remove: `${PEOPLE_DIRECTORY_BASE}/remove`,
  },
  postBatchComment: {
    create: `${POST_BATCH_COMMENT_BASE}/create`,
    edit: `${POST_BATCH_COMMENT_BASE}/edit`,
    get: `${POST_BATCH_COMMENT_BASE}/get`,
  },
  pulse: {
    createPulsePersonalThinking: `${PULSE_BASE}/create-personal-thinking`,
    getUserPulsePersonalThinkings: `${PULSE_BASE}/get-user-personal-thinkings`,
    getPulseData: `${PULSE_BASE}/get-pulse-data`,
    manager: {
      getPulseData: `${PULSE_BASE}/manager/getPulseData`,
    },
  },
  pulseSharing: {
    process: `${PULSE_SHARING_BASE}/process`,
  },
  resetTestData: {
    peerSelectionUser: `${ADMIN_BASE}/reset-peer-selector`,
  },
  respondent: {
    getSelf: `${RESPONDENT_BASE}/get-self`,
    getResults: `${RESPONDENT_BASE}/get-results`,
    updateShowAiSelfAnalysis: `${RESPONDENT_BASE}/get-updateShowAiSelfAnalysis`,
  },
  selfComment: {
    create: `${SELF_COMMENT_BASE}/create`,
    edit: `${SELF_COMMENT_BASE}/edit`,
  },
  surveyEditor: {
    getAccessibles: `${SURVEY_EDITOR_BASE}/getAccessibles`,
    getQuestion: `${SURVEY_EDITOR_BASE}/getQuestion`,
    getSurvey: `${SURVEY_EDITOR_BASE}/getSurvey`,
    userEditQuestionInSurvey: `${SURVEY_EDITOR_BASE}/userEditQuestionInSurvey`,
  },
  engagementSnapshot: {
    getOne: `${ENGAGEMENT_SNAPSHOT_BASE}/getOne`,
  },
  surveyModules: {
    getSelf: `${SURVEY_MODULES_BASE}/get-self`,
    markDone: `${SURVEY_MODULES_BASE}/mark-done`,
    getDiagnosticComment: `${SURVEY_MODULES_BASE}/get-diagnostic-comment`,
    createDiagnosticComment: `${SURVEY_MODULES_BASE}/create-diagnostic-comment`,
    editDiagnosticComment: `${SURVEY_MODULES_BASE}/edit-diagnostic-comment`,
    getAiSummaryFaceted: `${SURVEY_MODULES_BASE}/getAiSummaryFaceted`,
    generateAiSummaryFaceted: `${SURVEY_MODULES_BASE}/generateAiSummaryFaceted`,
    editAiSummaryFaceted: `${SURVEY_MODULES_BASE}/editAiSummaryFaceted`,
    getCapabilitiesEvolutionAiSummary: `${SURVEY_MODULES_BASE}/getCapabilitiesEvolutionAiSummary`,
    createCapabilitiesEvolutionAiSummary: `${SURVEY_MODULES_BASE}/createCapabilitiesEvolutionAiSummary`,
  },
  surveyProcessed: { isSurveyProcessed: `${API_BASE}/survey-processed/is-survey-processed` },
  survey: {
    get: `${SURVEY_BASE}/get`,
    saveResponse: `${SURVEY_BASE}/save-response`,
    getFutureOfWork: `${SURVEY_BASE}/get-future-of-work`,
    getFutureOfWorkExists: `${SURVEY_BASE}/get-future-of-work-exists`,
    getIAReadyData: `${SURVEY_BASE}/get-ia-ready-data`,
    getIAReadyExist: `${SURVEY_BASE}/get-ia-ready-exist`,
    getAiReadyStatus: `${SURVEY_BASE}/get-ai-ready-status`,
    getResponses: `${SURVEY_BASE}/getResponses`,
  },
  teacherGradeSheet: {
    save: `${TEACHER_GRADE_SHEET_BASE}/save-response`,
  },
  trackSeenContent: {
    getIPTabs: `${TRACK_SEEN_CONTENT_BASE}/get-ip-tabs`,
    markIPTabAsSeen: `${TRACK_SEEN_CONTENT_BASE}/mark-ip-tab-as-seen`,
  },
  user: {
    getUserInfos: `${USER_BASE}/get-infos`,
    getUserUploadedInfos: `${USER_BASE}/get-uploaded-user-infos`,
    getTeamMemberInfos: `${USER_BASE}/get-team-member-infos`,
    getUserName: `${USER_BASE}/get-user-name`,
    editUserInfos: `${USER_BASE}/edit`,
    getLatestBatchIdWithGeneralScore: `${USER_BASE}/get-latest-batch-with-general-score`,
    isWithinTeamInDepth: `${USER_BASE}/is-within-team-in-depth`,
    getTeamInDepth: `${USER_BASE}/get-team-in-depth`,
    getAccessibleProfiles: `${USER_BASE}/getAccessibleProfiles`,
    exists: `${USER_BASE}/exists`,
    getUserToDo: `${USER_BASE}/get-user-todo`,
    getTeamInfos: `${USER_BASE}/getTeamInfos`,
  },
  companyProgress: {
    get: `${COMPANY_PROGRESS_BASE}/get`,
  },
  iaReady: {
    selfComment: {
      create: `${IA_READY_BASE}/create-self-comment`,
      edit: `${IA_READY_BASE}/edit-self-comment`,
    },
  },
  capabilities: {
    spider: {
      get: `${CAPABILITIES_BASE}/spider/get`,
    },
  },
  profileFlashers: {
    getDirectReportFlashers: `${PROFILE_FLASHERS_BASE}/get-direct-report-flashers`,
    getFilterSelectProfileFlashers: `${PROFILE_FLASHERS_BASE}/get-filter-select-profile-flashers`,
    getProfileFlashers: `${PROFILE_FLASHERS_BASE}/get-profile-flashers`,
    markTabAsFlashing: `${PROFILE_FLASHERS_BASE}/mark-tab-as-flashing`,
    markTabAsNotFlashing: `${PROFILE_FLASHERS_BASE}/mark-tab-as-not-flashing`,
  },
  translation: {
    getTranslation: `${TRANSLATION_BASE}/get-translation`,
  },
  nudge: {
    getNudges: `${NUDGE_BASE}/get-nudges`,
  },
  ericssonPoc: {
    getMetathemeAiSummaries: `${ERICSSON_POC_BASE}/getMetathemeAiSummaries`,
    generateMetathemeAiSummary: `${ERICSSON_POC_BASE}/generateMetathemeAiSummary`,
    editMetathemeAiSummary: `${ERICSSON_POC_BASE}/editMetathemeAiSummary`,
    getMetathemeAiAnalyses: `${ERICSSON_POC_BASE}/getMetathemeAiAnalyses`,
  },
  forfaitJourInterviews: {
    getTeamInterviews: `${FORFAIT_JOUR_INTERVIEWS_BASE}/getTeamInterviews`,
    getTeamInDepthInterviews: `${FORFAIT_JOUR_INTERVIEWS_BASE}/getTeamInDepthInterviews`,
    getCompanyInterviews: `${FORFAIT_JOUR_INTERVIEWS_BASE}/getCompanyInterviews`,
    getUserForfaitJourInterviews: `${FORFAIT_JOUR_INTERVIEWS_BASE}/getUserForfaitJourInterviews`,
    signInterviewManager: `${FORFAIT_JOUR_INTERVIEWS_BASE}/signInterviewManager`,
    signInterviewEmployee: `${FORFAIT_JOUR_INTERVIEWS_BASE}/signInterviewEmployee`,
  },
  learningProgram: {
    getAccessibleLearningPrograms: `${LEARNING_PROGRAM_BASE}/getAccessibleLearningPrograms`,
    getLearningProgram: `${LEARNING_PROGRAM_BASE}/getLearningProgram`,
    getLearningProgramModulePhasesCompletion: `${LEARNING_PROGRAM_BASE}/getLearningProgramModulePhasesCompletion`,
    getLPAggPhaseAiSummaries: `${LEARNING_PROGRAM_BASE}/getLPAggPhaseAiSummaries`,
    generateLPAggPhaseSummary: `${LEARNING_PROGRAM_BASE}/generateLPAggPhaseSummary`,
    editLPAggPhaseSummary: `${LEARNING_PROGRAM_BASE}/editLPAggPhaseSummary`,
  },
} as const
